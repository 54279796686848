import { ApplicationRef, Injectable, inject } from "@angular/core";

import { Observable, first, of, share, tap } from "rxjs";

import { AccessService } from "../access.service";
import { ProctorService } from "../proctor.service";

@Injectable({
  providedIn: "root",
})
export abstract class DataBaseService<T> {
  protected data?: T | null = null;
  private refreshObservable?: Observable<T> | null;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor(init = true) {
    const appRef = inject(ApplicationRef);
    const accessService = inject(AccessService);
    const proctorService = inject(ProctorService);

    accessService.accessChanged$.subscribe((_ac) => {
      this.getData(true).subscribe();
    });

    proctorService.info.$proctorChanged.subscribe((_pc) => {
      this.getData(true).subscribe();
    });

    if (init) {
      appRef.isStable.pipe(first((isStable) => isStable === true)).subscribe({
        next: () => {
          this.init();
        },
      });
    }
  }

  init() {}

  abstract refresher(): Observable<T>;

  getData(refresh = false): Observable<T> {
    if (this.refreshObservable != null) {
      return this.refreshObservable;
    }

    if (this.data != null && !refresh) {
      return of(this.data);
    }

    this.refreshObservable = this.refresher().pipe(
      share(),
      tap((data) => {
        this.data = data;
        this.refreshObservable = null;
      }),
    );

    return this.refreshObservable;
  }
}
